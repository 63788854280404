import React, {Component} from 'react';
import styled from 'styled-components';
import { LIGHT_GREEN, DARK_GREEN } from '../../constants/Colors';
import Heading from '../typograph/Heading';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import bannersHero02Desk from '../../images/BannerHero-Curso-Homeopatia-Desk.jpg';
import bannerSistemas from '../../images/BannersHero-sistemas01.jpg';
import bannerEstatistica from '../../images/BannersHero-estatistica.jpg';
import catHero from '../../images/BannerHero-MiniCurso-Antroposofia.jpg';
import ebramecSeal from '../../images/ebramec-banners.png';
import LinkButton from '../buttons/LinkButton';


class HomeCarousel extends Component {
    render() {
        return (
            <Carousel
                interval={10000} 
                showArrows={false} 
                showThumbs={false} 
                showStatus={false} 
                infiniteLoop 
                autoPlay>
                
                {this.props.children}
                
            </Carousel>
        );
    }
};

const Container = styled.div`
    width: 100%;
    height: 511px; 
`

const HeroContainer = styled.div`
    width: 100%;
    height: 511px; 
    background-image: url('${({image}) => image}');
    background-position: center;

    @media screen and (max-width: 320px){
        background-position: 1327px center;
    }

    @media screen and (max-width: 375px){
        background-position: 1365px center;
    }

    @media screen and (max-width: 420px){
        background-position: 1400px center;
    }

`

const TextContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 100px;
    text-align: left;
    color: ${({color}) => color ? color : '#fff'};
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (max-width: 340px){
        padding-top: 80px;
    }

`

const TextContainerEbramec = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 100px;
    text-align: left;
    color: ${({color}) => color ? color : '#fff'};
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (max-width: 944px){
        padding-top: 40px;
    }

    @media screen and (max-width: 340px){
        padding-top: 40px;
    }

`

const TextShadow = styled.div`
    text-shadow: 2px 4px 10px rgba(0,0,0,0.6);
`


const HomeHero = ({...props}) => (
    <Container>
        <HomeCarousel>
            <HeroContainer image={bannersHero02Desk}>
                <TextContainerEbramec>
                    <TextShadow>
                        <Heading type='h2' style={{lineHeight: '40px'}}  color={LIGHT_GREEN} >
                            Homeopatia Veterinária <br />
                            e Tecnologia das Altas Diluições
                        </Heading>
                    </TextShadow>
                    <span style={{display: 'block', maxWidth:'400px', paddingRight:10}}><b>Pós-Graduação e formação com turmas formadas nos meses de fevereiro e agosto.</b></span><br /><br />
                    <LinkButton to="/cursos-e-minicursos/homeopatia-para-veterinarios/">
                        SAIBA MAIS
                    </LinkButton><br /><br />
                    <img src={ebramecSeal} style={{width:110, height:110}} alt="Selo Ebramec"/>
                </TextContainerEbramec>
            </HeroContainer>
            <HeroContainer image={bannerSistemas}>
                <TextContainerEbramec>
                    <TextShadow>
                        <Heading type='h2' style={{lineHeight: '40px'}}  color={LIGHT_GREEN} >
                            Homeopatia Veterinária:<br />
                            Clínica Avançada<br />
                        </Heading>
                    </TextShadow>
                    <span style={{display: 'block', maxWidth:'400px', paddingRight:10}}>
                        <b>Pós-graduação composta por 12 módulos contratados em conjunto.
                        <br /><br /></b>
                    </span>
                    <LinkButton to="/cursos-e-minicursos/homeopatia-veterinaria-clinica-avancada">
                        SAIBA MAIS
                    </LinkButton><br/><br/>
                    <img src={ebramecSeal} style={{width:110, height:110}} alt="Selo Ebramec"/>
                </TextContainerEbramec>
            </HeroContainer>
            <HeroContainer image={bannerEstatistica}>
                <TextContainer>
                    <TextShadow>
                        <Heading type='h2' style={{lineHeight: '40px'}}  color={LIGHT_GREEN} >
                            Em Breve <br />
                            Mini-Curso de Estatística
                        </Heading>
                    </TextShadow>
                    <b>
                        <span style={{display: 'block', maxWidth:'400px'}}>Aguardando formação de turma<br /> para definição de data</span><br />
                    </b>
                    <LinkButton to="/contato/">
                        FALE CONOSCO E SAIBA MAIS
                    </LinkButton>
                </TextContainer>
            </HeroContainer>
            <HeroContainer image={catHero}>
                <TextContainer color={DARK_GREEN}>
                    
                    <Heading type='h2' style={{lineHeight: '40px'}}  color={DARK_GREEN} >
                        Mini-Curso de Fundamentos <br />
                        da Antroposofia
                    </Heading>
                    <b>
                        <span style={{display: 'block', maxWidth:'400px'}}>Aguardando formação de turma<br /> para definição de data</span><br />
                    </b>
                    <LinkButton to="/contato/">
                        FALE CONOSCO E SAIBA MAIS
                    </LinkButton>
                </TextContainer>
            </HeroContainer>
        </HomeCarousel>
    </Container>
);

export default HomeHero;