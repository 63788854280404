import React from 'react'
import Heading from '../components/typograph/Heading'

import Layout from '../components/layout/Layout'
import IlustratedContent from '../components/ilustratedParagraph/IlustratedContent'
import ImgHomeopatia1 from '../images/BenefHomeopatia-01.png'
import ImgHomeopatia2 from '../images/BenefHomeopatia-02.png'
import ImgHomeopatia3 from '../images/BenefHomeopatia-03.png'
import ImgHomeopatia4 from '../images/BenefHomeopatia-04.png'
import styled from 'styled-components'
import Testimonials from '../components/testimonials/Testimonials'
import PageContainer from '../components/layout/PageContainer'
import HomeHero from '../components/hero/HomeHero'

const IlustrationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 1044px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const IndexPage = () => (
  <Layout
    meta={[
      {
        name: 'description',
        content:
          'A HDScience oferece cursos e mini-cursos sobre homeopatia para veterinários e farmacêuticos.',
      },
      {
        name: 'keywords',
        content:
          'homeopatia, veterinária, cromoterapia, curso, cursos, mini-curso, mini-cursos',
      },
      { property: 'og:url', content: 'http://www.hdscience.com.br' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'HDScience - Home' },
      {
        property: 'og:description',
        content:
          'A HDScience oferece cursos e mini-cursos sobre homeopatia para veterinários e farmacêuticos.',
      },
    ]}
  >
    <HomeHero />
    <PageContainer>
      <Heading type="h2" style={{ textAlign: 'center' }}>
        Porque Estudar e Trabalhar com Homeopatia?
      </Heading>
      <IlustrationsContainer>
        <IlustratedContent image={ImgHomeopatia1}>
          A homeopatia cria condições para que o organismo se reequilibre,
          retomando seu estado saudável.
        </IlustratedContent>
        <IlustratedContent image={ImgHomeopatia2}>
          Apresenta ótima aceitação por todas as espécies, além de dar a
          possibilidade de administrar de várias formas, facilitando para
          veterinários e tutores.
        </IlustratedContent>
        <IlustratedContent image={ImgHomeopatia3}>
          Trabalha em prol do bem estar animal já que não produz efeitos tóxicos
          aos indivíduos.
        </IlustratedContent>
        <IlustratedContent image={ImgHomeopatia4}>
          É uma ciência médica legalizada pelos Conselhos Federais.
        </IlustratedContent>
      </IlustrationsContainer>
    </PageContainer>
    <Testimonials />
  </Layout>
)

export default IndexPage
