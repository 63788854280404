import React, {Component} from 'react';
import styled from 'styled-components';
import { LIGHT_GRAY, TEXT } from '../../constants/Colors';
import Heading from '../typograph/Heading';
import Text from '../typograph/Text';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import aspasAntes from '../../images/aspas-antes.png'
import aspasDepois from '../../images/aspas-depois.png'

class TestimonialsCarousel extends Component {
    render() {
        return (
            <Carousel
                interval={10000} 
                showArrows={false} 
                showThumbs={false} 
                showStatus={false} 
                infiniteLoop 
                autoPlay>
                <TextContainer>
                    <div style={{textAlign:'right', maxWidth:300, marginRight:40, color:TEXT}}>
                        <Heading type='h3' style={{color:TEXT, margin:'18px 0', padding:0}}>
                        Franscinne Narita
                        </Heading>
                        CRMV 33791
                    </div>
                    <div  style={{textAlign:'left', maxWidth:800}}>
                        
                        <Text type='p'>
                        <img src={aspasAntes} className='up' alt="Aspas estilizadas"/>
                        Sempre admirei a homeopatia e sempre quis estudar para aplicar nos meus pacientes, porém com a correria do dia a dia e a demanda do trabalho foi impossível de iniciar antes. Em março de 2017 fui a um curso de homeopatia com a Profª Cideli, já era apaixonada pela homeopatia, mas fiquei cada vez mais encantada, conversei com ela que queria fazer a especialização e se ela me indicaria um lugar de referência e ela me contou do sonho de fazer o próprio curso de especialização e pediu para eu aguardar as novidades. Em alguns meses ela divulgou a HD Science e para minha felicidade iria iniciar a primeira turma de Homeopatia. Fui a primeira a se inscrever e a ansiedade era incontrolável para começar a estudar e aplicar a homeopatia. No primeiro dia de aula teve a apresentação de toda a turma e dos professores, a experiência compartilhada da Cideli e do Adalberto faziam meus olhos brilharem, centenas de histórias incríveis e com resultados maravilhosos me instigavam para aprender tudo e começar a aplicar em todos os animais. E foi isso que aconteceu, já tratei diversas espécies, como pinguins, peixes, tubarão, primatas, cães com dermatites há anos e os resultados foram incríveis. É muito gratificante ter resultados eficientes, rápidos e fazer parte da HD Science. Todas as aulas são maravilhosas e eu só tenho que agradecer a esses mestres que compartilham com muita dedicação e conhecimento tudo sobre a homeopatia.
                        <img src={aspasDepois}  alt="Aspas estilizadas"/>
                        </Text>
                    </div>
                </TextContainer>
                <TextContainer style={{paddingTop:100}}>
                    <div style={{textAlign:'right', maxWidth:300, marginRight:40, color:TEXT}}>
                        <Heading type='h3' style={{color:TEXT, margin:'18px 0', padding:0}}>Bruna Scardoeli</Heading>
                        CRMV 42630
                    </div>
                    <div  style={{textAlign:'left', maxWidth:800}}>
                        <Text type='p'>
                        <img src={aspasAntes} className='up' alt="Aspas estilizadas"/>
                        Frente a tantos casos onde tratamos o animal para diversas doenças e esquecemos do indivíduo como um todo, a homeopatia veio exatamente para fazer valer o bem estar, tratar o animal sem gerar qualquer efeito tóxico ou colateral e sem gerar resistência. Já tratei cistite, constipação, diversos casos dermatológicos e respiratórios, ansiedade por separação, agressividade, entre outros, com um sucesso maior e mais rápido do que na alopatia. A homeopatia está aí para fazer a diferença e para isso, contamos com os professores experientes e aulas teóricas e práticas na HD Science, na qual nos inspira cada dia mais a continuarmos nesse segmento maravilhoso da medicina.
                        <img src={aspasDepois}  alt="Aspas estilizadas"/>
                        </Text>
                    </div>
                </TextContainer>
            </Carousel>
        );
    }
};

const Container = styled.div`
    width: 100%;
    text-align: center;
    background-color: ${LIGHT_GRAY};
    height: 620px;
    margin-top: 20px;
    padding: 20px 0px 20px 0px;
    z-index: 0;

    @media (max-width: 800px) {
        height: 750px;
    }

    @media (max-width: 600px) {
        height: 890px;
    }

    @media (max-width: 500px) {
        height: 1140px;
    }

    
`

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${LIGHT_GRAY};
    padding-top: 50px;
    height: 500px;

    @media (max-width: 1044px) {
        flex-wrap: wrap;
        padding: 20px;
    }

    @media (max-width: 1043px){
        font-size:15px;
    }

    @media (max-width: 800px){
        height: 600px;
    }

    @media (max-width: 600px){
        height: 700px;
    }

    @media (max-width: 500px){
        height: 1000px;
    }

    

`

const Testimonials = ({...props}) => (
    <Container className='testimonails'>
        <Heading type='h2' >Depoimentos de Alunos</Heading>
        <TestimonialsCarousel />
    </Container>
);

export default Testimonials;